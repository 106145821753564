import * as _stream2 from "stream";

var _stream = "default" in _stream2 ? _stream2.default : _stream2;

import * as _lowercaseKeys2 from "lowercase-keys";

var _lowercaseKeys = "default" in _lowercaseKeys2 ? _lowercaseKeys2.default : _lowercaseKeys2;

import _buffer from "buffer";
var exports = {};
var Buffer = _buffer.Buffer;
const Readable = _stream.Readable;
const lowercaseKeys = _lowercaseKeys;

class Response extends Readable {
  constructor(statusCode, headers, body, url) {
    if (typeof statusCode !== "number") {
      throw new TypeError("Argument `statusCode` should be a number");
    }

    if (typeof headers !== "object") {
      throw new TypeError("Argument `headers` should be an object");
    }

    if (!(body instanceof Buffer)) {
      throw new TypeError("Argument `body` should be a buffer");
    }

    if (typeof url !== "string") {
      throw new TypeError("Argument `url` should be a string");
    }

    super();
    this.statusCode = statusCode;
    this.headers = lowercaseKeys(headers);
    this.body = body;
    this.url = url;
  }

  _read() {
    this.push(this.body);
    this.push(null);
  }

}

exports = Response;
export default exports;